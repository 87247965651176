(function($) {
    $("a.section-scroll").on('click',function (e) {

        e.preventDefault();

        // Assuming that the button is in a .section, go to the next .section
        var $target = $(this).closest(".section").next();
        $('html, body').animate({scrollTop: $target.offset().top -20 }, 500);

    });

    // Testimonials Carousel
    // ---------------------------------------------------------------------------
    // $('#testimonials-carousel').carousel({
    //
    //     interval: 1000
    //
    // });

    $('#pause-carousel').click(function() {

        $('#testimonials-carousel').carousel('pause');
        $(this).hide();
        $( "#play-carousel").fadeIn(200);

    });

    $('#play-carousel').click(function() {

        $('#testimonials-carousel').carousel({
            interval: 8000
        });
        $(this).hide();
        $( "#pause-carousel").fadeIn(200);

    });

})(jQuery);
